import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import { LayoutSplash } from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <LayoutSplash>
    <SEO />
    <Helmet><body className="splash" /></Helmet>
    <h1 className="error">404</h1>
    <nav>
      <ul>
        <li><Link className="btn" to="/">Home</Link></li>
        <li><Link className="btn" to="/contact">Contact</Link></li>
      </ul>
    </nav>
  </LayoutSplash>
);

export default IndexPage;
